import 'filepond/dist/filepond.min.css';
import { FilePond } from 'react-filepond';
import React, { useState, useEffect, useRef } from 'react';
import './App.css';

// Fonction debounce
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function App() {

  const [formData, setFormData] = useState({
    raisonSociale: '',
    siret: '',
    naf: '',
    tel: '',
    fax: '',
    portable: '',
    adresse: '',
    codePostal: '',
    ville: '',
    email: '',
    nom: '',
    prenom: '',
    fonction: '',
    typeConnexion: [],
    autreType: '',
    debit: '',
    users: [{ name: '', function: '', type: '' }, { name: '', function: '', type: '' }, { name: '', function: '', type: '' }]
  });

  const [searchResults, setSearchResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultSelected, setResultSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const firstErrorRef = useRef(null);

  // Ajout de useEffect pour la recherche
  useEffect(() => {
    const base_url = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";
    const url = `${base_url}/pappers`;
    const performSearch = debounce(() => {
      if (resultSelected) {
        return;
      }
      if (formData.raisonSociale.length > 2) {
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': "RdMosVSOjw2WSu1cSU9nOaBZTJMjuE9qEwY2Qhac"
          },
          body: JSON.stringify({ payload: { raison_sociale: formData.raisonSociale } }),
        })
          .then(response => response.json())
          .then(data => {
            setSearchResults(data.companies);
            setShowSuggestions(true);
          })
          .catch(error => console.error('Error:', error));
      }
    }, 300);
    performSearch();
  }, [formData.raisonSociale, resultSelected]);

  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]); 

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: false }));
    }
    if (name.includes('users')) {
      const users = [...formData.users];
      //remove users- from name
      const _name = name.replace('users-', '');
      const [field, userIndex] = _name.split('-');
      users[userIndex][field] = value;
      setFormData({ ...formData, users });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  function handleCheckboxChange(e) {
    const { value } = e.target;
    let newArray;

    if (formData.typeConnexion.includes(value)) {
      newArray = formData.typeConnexion.filter(type => type !== value);
    } else {
      newArray = [...formData.typeConnexion, value];
    }

    setFormData(prevState => ({
      ...prevState,
      typeConnexion: newArray
    }));
    // Revalider le champ pour mettre à jour les messages d'erreur
    validateTypeConnexion(newArray);
}

// Gestion de la validation des types de connexion
function validateTypeConnexion(newArray) {
    const newErrors = { ...errors };

    if (newArray.length === 0) {
        newErrors.typeConnexion = "Veuillez sélectionner au moins un type de connexion.";
    } else {
        delete newErrors.typeConnexion;
    }

    setErrors(newErrors);
}

  const addUser = () => {
    setFormData({
      ...formData,
      users: [...formData.users, { name: '', function: '', type: '', appWanted: true }]
    });
  };

  const removeUser = (indexToRemove) => {
    setFormData({
      ...formData,
      users: formData.users.filter((_, index) => index !== indexToRemove)
    });
  };

  function generateJson() {
    const data = formData;
    const json = {
      "coordonnees": {
        "raisonSociale": data.raisonSociale,
        "siret": data.siret,
        "naf": data.naf,
        "tel": data.tel,
        "fax": data.fax,
        "portable": data.portable,
        "adresse": data.adresse,
        "codePostal": data.codePostal,
        "ville": data.ville,
        "email": data.email,
      },
      "signataire": {
        "nom": data.nom,
        "prenom": data.prenom,
        "fonction": data.fonction,
      },
      "typeConnexion": data.typeConnexion,
      "utilisateurs": data.users
    }
    return json;
  }
  
  const selectCompany = (company) => {
    setResultSelected(true);
    setFormData({
      ...formData,
      raisonSociale: company.nom_entreprise,
      siret: company.siege.siret,
      naf: company.code_naf,
      adresse: company.siege.adresse_ligne_1,
      codePostal: company.siege.code_postal,
      ville: company.siege.ville,
    });
    setShowSuggestions(false); // Ferme les suggestions après sélection
  };


  const submit = async (event) => {
    event.preventDefault(); // Empêcher le rechargement de la page
    setSubmitting(true);
    const base_url = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";
    
    const newErrors = {};
    let hasError = false;

    // Liste des champs requis avec messages d'erreur personnalisés
    const requiredFields = {
        tel: "Le champ Téléphone est obligatoire.",
        portable: "Le champ Portable est obligatoire.",
        nom: "Le champ Nom est obligatoire.",
        prenom: "Le champ Prénom est obligatoire.",
        fonction: "Le champ Fonction est obligatoire.",
        adresse: "Le champ Adresse est obligatoire.",
        codePostal: "Le champ Code Postal est obligatoire.",
        ville: "Le champ Ville est obligatoire.",
        email: "Le champ Email est obligatoire."
    };

    // Renvoi du nom du champ si vide
    Object.keys(requiredFields).forEach(field => {
      if (!formData[field].trim()) {
          newErrors[field] = requiredFields[field];
          hasError = true;
          if (!firstErrorRef.current) {
              firstErrorRef.current = document.getElementsByName(field)[0];
          }
      }
    });

    // Champ type de connexion vide
    if (formData.typeConnexion.length === 0) {
      newErrors.typeConnexion = "Veuillez sélectionner au moins un type de connexion.";
      if (!firstErrorRef.current) {
        firstErrorRef.current = document.querySelector('[name="typeConnexion"]');
      }
      hasError = true;
    }
    
    // Scroll vers le champ en erreur
    if (hasError) {
        setErrors(newErrors);
        firstErrorRef.current.scrollIntoView({ behavior: 'smooth' }); // Défile vers le premier champ avec erreur
        setSubmitting(false);
        return;
    }

    // Remise à zéro pour la prochaine validation
    firstErrorRef.current = null;

    // Demande des URLs signées et téléchargement des fichiers sur S3
    const uploadFilesToS3 = async () => {
      return Promise.all(files.map(async (fileItem) => {
        const file = fileItem.file;
        const presignResponse = await fetch(`${base_url}/generate-presigned-url`, {
          method: 'POST',
          body: JSON.stringify({ file_name: file.name, content_type: file.type }),
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': "RdMosVSOjw2WSu1cSU9nOaBZTJMjuE9qEwY2Qhac"
          }
        });
        const { url } = await presignResponse.json();
        console.log("URL signée:", url);
        await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });
        return file.name; // On utilise le nom du fichier comme référence
      }));
    };
  
    // Préparation du payload du formulaire avec les références de fichiers
    const fileReferences = await uploadFilesToS3();
    const payload = {
      "payload": {
        ...generateJson(),
        "fileNames": fileReferences // Ajout des noms de fichiers au payload
      }
    };
  
    console.log(payload);
    console.log(JSON.stringify(payload));
  
    // Soumission du formulaire avec les références de fichiers
    fetch(`${base_url}/form`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': "RdMosVSOjw2WSu1cSU9nOaBZTJMjuE9qEwY2Qhac"
      }
    }).then(res => res.json())
      .then(response => {
        setSubmitting(false);
        const toast = new window.bootstrap.Toast(document.getElementById('liveToast'));
        toast.show();
      })
      .catch(error => {
        setSubmitting(false);
        const toast = new window.bootstrap.Toast(document.getElementById('errorToast'));
        toast.show();
      });
  };
  

  // Gérer le clic en dehors pour fermer les suggestions
  useEffect(() => {

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <>
      <div id="liveToast" className="toast align-items-center text-bg-success" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="toast-body">
            <i className="bi bi-check-circle-fill me-2"></i>
            <b>Votre fiche de renseignement à bien été transmise.</b>
          </div>
          <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
      <div id="errorToast" className="toast align-items-center text-bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="toast-body">
            <i className="bi bi-x-circle-fill me-2"></i>
            <b>Une erreur est survenue, merci de réessayer dans quelques minutes.</b>
          </div>
          <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
      <div className="container mt-5 border teleform-container p-4 p-md-5">
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-12 col-md-6 d-flex justify-content-md-start justify-content-center align-items-center mb-3 mb-md-0 mt-3 mt-md-0">
            <a href="https://www.global-telecom.fr/" target='_blank' rel="noreferrer">
              <img src="global-telecom-ligne.jpg" alt="Logo de l'entreprise" className="img-fluid" style={{ maxWidth: '300px' }} />
            </a>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-center mb-md-0 mt-1 mt-md-0">
            <a href="https://tilt-groupe.com" target='_blank' rel="noreferrer">
              <img src="tilt-groupe.png" alt="Logo de l'entreprise" className="img-fluid" style={{ maxWidth: '200px' }} />
            </a>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center mb-5 mt-5 xs-12">
          <div className="col-auto text-center">
            <h1 className="mobile-large-text">Fiche de renseignement</h1>
          </div>
        </div>
        <form>
        <h2 className="mb-3">Coordonnées</h2>
        <div className="row">
          <div className="col-md-4 position-relative">
            <input type="text" className="form-control mb-2" placeholder="Raison sociale" name="raisonSociale" value={formData.raisonSociale} onChange={handleInputChange} />
            {showSuggestions && (
              <div className="auto-complete-results">
                {searchResults && searchResults.map((company, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => selectCompany(company)}
                  >
                    <strong>{company.nom_entreprise}</strong> - {company.siege.ville}, SIRET: {company.siege.siret}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-md-4">
            <input type="text" className="form-control mb-2" placeholder="Siret" name="siret" value={formData.siret} onChange={handleInputChange} />
          </div>
          <div className="col-md-4">
            <input type="text" className="form-control mb-2" placeholder="Naf" name="naf" value={formData.naf} onChange={handleInputChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="tel" className={`form-control mb-2 ${errors.tel ? 'input-error' : ''}`} placeholder="Tel*" name="tel" required value={formData.tel} onChange={handleInputChange} />
            {errors.tel && <div className="error-input-message">{errors.tel}</div>}
          </div>
          <div className="col-md-4">
            <input type="text" className="form-control mb-2" placeholder="Fax" name="fax" value={formData.fax} onChange={handleInputChange} />
          </div>
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.portable ? 'input-error' : ''}`} placeholder="Portable*" name="portable" required value={formData.portable} onChange={handleInputChange} />
            {errors.portable && <div className="error-input-message">{errors.portable}</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="tel" className={`form-control mb-2 ${errors.adresse ? 'input-error' : ''}`}  placeholder="Adresse*" required name="adresse" value={formData.adresse} onChange={handleInputChange} />
            {errors.adresse && <div className="error-input-message">{errors.adresse}</div>}
          </div>
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.codePostal ? 'input-error' : ''}`} placeholder="Code Postal*" name="codePostal" required value={formData.codePostal} onChange={handleInputChange} />
            {errors.codePostal && <div className="error-input-message">{errors.codePostal}</div>}
          </div>
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.ville ? 'input-error' : ''}`} placeholder="Ville*" required name="ville" value={formData.ville} onChange={handleInputChange} />
            {errors.ville && <div className="error-input-message">{errors.ville}</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <input type="email" className={`form-control mb-2 ${errors.email ? 'input-error' : ''}`} placeholder="Email*" required name="email" value={formData.email} onChange={handleInputChange} />
            {errors.email && <div className="error-input-message">{errors.email}</div>}
          </div>
        </div>

        <h2 className="mb-3 mt-5 mt-md-4">Signataire</h2>
        <div className="row mb-3">
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.nom ? 'input-error' : ''}`} placeholder="Nom*" name="nom" required value={formData.nom} onChange={handleInputChange} />
            {errors.nom && <div className="error-input-message">{errors.nom}</div>}
          </div>
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.prenom ? 'input-error' : ''}`} placeholder="Prénom*" name="prenom" required value={formData.prenom} onChange={handleInputChange} />
            {errors.prenom && <div className="error-input-message">{errors.prenom}</div>}
          </div>
          <div className="col-md-4">
            <input type="text" className={`form-control mb-2 ${errors.fonction ? 'input-error' : ''}`} placeholder="Fonction*" name="fonction" required value={formData.fonction} onChange={handleInputChange} />
            {errors.fonction && <div className="error-input-message">{errors.fonction}</div>}
          </div>
        </div>

        {/* Type de connexion internet */}
        <h2 className="mb-3 mt-5" name="typeConnexion">Type de connexion internet</h2>
        <div className="mb-3">
          <div className="d-flex">
            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">

              <input type="checkbox" className="btn-check" id="Fibre" value="Fibre" checked={formData.typeConnexion.includes('Fibre')} onChange={handleCheckboxChange} />
              <label htmlFor="Fibre" className={`btn btn-outline-primary btn-connection-type ${errors.typeConnexion ? 'input-error' : ''}`}>Fibre</label>

              <input type="checkbox" className="btn-check" id="ADSL" value="ADSL" checked={formData.typeConnexion.includes('ADSL')} onChange={handleCheckboxChange} />
              <label htmlFor="ADSL" className={`btn btn-outline-primary btn-connection-type adsl ${errors.typeConnexion ? 'input-error' : ''}`}>ADSL</label>

              <input type="checkbox" className="btn-check" id="Modem4G" value="Modem 4G" checked={formData.typeConnexion.includes('Modem 4G')} onChange={handleCheckboxChange} />
              <label htmlFor="Modem4G" className={`btn btn-outline-primary btn-connection-type ${errors.typeConnexion ? 'input-error' : ''}`}>Modem 4G</label>*
            </div>
          </div>
          {errors.typeConnexion && <div className="error-input-message" style={{ paddingTop: '7px' }}>{errors.typeConnexion}</div>}
        </div>
        <h2 className="mb-3 mt-5">Vos documents / factures</h2>
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          name="files" // Nom à utiliser dans les données de formulaire
          labelIdle="Glissez-déposez vos fichiers ou <span class='filepond--label-action'>Choisissez un fichier</span>"
        />

        <h2 className="mb-3 mt-5">Utilisateurs de la téléphonie</h2>
        {formData.users.map((user, index) => (
          <div key={index} className="row mb-12">
            <div className="col-md-4">
              <input type="text" className="form-control mb-2" id={`name-${index}`} placeholder="Nom - Prénom" name={`users-name-${index}`} value={user.name} onChange={handleInputChange} />
            </div>
            <div className="col-md-4">
              <input type="text" className="form-control mb-2" id={`function-${index}`} placeholder="Fonction" name={`users-function-${index}`} value={user.function} onChange={handleInputChange} />
            </div>
            <div className="col-md-3">
              <select className="form-select mb-2" id={`poste-${index}`} name={`users-type-${index}`} value={user.type} onChange={handleInputChange}>
                <option defaultValue={true}>Choix du matériel</option>
                <optgroup label="Collaboration mobile">
                  <option value="Collaboration mobile">Collaboration mobile</option>
                </optgroup>
                <optgroup label="Welcome Console">
                  <option value="Welcome Console">Welcome Console</option>
                  <option value="Welcome Console + Collaboration mobile">Welcome Console + Collaboration mobile</option>
                </optgroup>


                <optgroup label="Workforce">
                  <option value="Workforce">Workforce</option>
                  <option value="Workforce + Collaboration mobile">Workforce + Collaboration mobile</option>
                </optgroup>

                <optgroup label="Start">
                  <option value="Start">Start</option>
                  <option value="Start + Collaboration mobile">Start + Collaboration mobile</option>
                </optgroup>

                <optgroup label="Wair">
                  <option value="Wair (sans fil)">Wair (sans fil)</option>
                  <option value="Wair (sans fil) + Collaboration mobile">Wair (sans fil) + Collaboration mobile</option>
                </optgroup>

              </select>
            </div>
            <div className="col-md-1">
              <div className="d-grid gap-2">
                <button className="btn me-0 btn-warning mb-4 mb-md-2" onClick={() => removeUser(index)}>
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary add-user-button btn-sm" onClick={addUser}>+ Ajouter un utilisateur</button>
            </div>
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-success input-block-level btn-submit" onClick={submit}>
              <span className="sr-only">Envoyer le formulaire</span>
              {submitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <div></div>}
              
              </button>
          </div>
        </div>
        </form>
        <br className="d-none d-md-block" />
        <div className="d-flex justify-content-between mt-12 flex-wrap">
          <div className="text-center col-12 col-md-3">
            <div>Collaboration mobile</div>
            <img src="application-collaboration-mobile.png" alt="Application de collaboration et mobile" className="img-fluid footer-img mt-2 mt-md-3" />
          </div>
          <div className="text-center col-12 col-md-3 mt-3 mt-md-0">
            <div>Welcome Console</div>
            <img src="welcome-console.jpg" alt="Welcome Console" className="img-fluid footer-img mt-md-3" />
          </div>
          <div className="text-center col-12 col-md-2 mt-3 mt-md-0">
            <div>Workforce</div>
            <img src="workforce.png" alt="Workforce" className="img-fluid footer-img mt-2 mt-md-3" />
          </div>
          <div className="text-center col-12 col-md-2 mt-3 mt-md-0">
            <div>Start</div>
            <img src="start.jpg" alt="Start" className="img-fluid footer-img mt-2 mt-md-3" />
          </div>
          <div className="text-center col-12 col-md-1 mt-3 mt-md-0">
            <div>Wair</div>
            <img src="wair.png" alt="Wair" className="img-fluid footer-img mt-2 mt-md-3" />
          </div>
        </div>
      </div>
      
    </>
  );
}

export default App;
